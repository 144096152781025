/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line require-jsdoc
const createUrlParams = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const Journey_Variant = urlParams.get('Journey_Variant')
  const scenterPromo = urlParams.get('scenterPromo')
}

// eslint-disable-next-line require-jsdoc
export const paramsObject = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  // eslint-disable-next-line camelcase
  const Journey_Variant = urlParams.get('Journey_Variant')
  const scenterPromo = urlParams.get('scenterPromo')
  return {
    Journey_Variant,
    scenterPromo
  }
}

export default createUrlParams
