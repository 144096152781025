import React from 'react'
import styles from './ScanMethods.module.css'
import MethodCard from '../MethodCard/MethodCard'
import onSiteImg from '../../Assets/images/png/on-site.png'
import printKit from '../../Assets/images/png/printKit.png'
import content from './ScanMethods.content'

/**
 * HiMoons component UI to show the great properties of moons.
 * @returns {JSX.Element} A title with 3 sentences. Each one with images.
 */
const ScanMethods = ({ country, publicKey }) => {
  const colombia = country === 'colombia' || country === 'Colombia'
  const countryText = {
    onSite: {
      subtitle: {
        subtitleOne: content.onSite.mx.subtitleOne,
        bolder: content.onSite.mx.bolder,
        subtitleTwo: content.onSite.mx.subtitleTwo,
      },
      price: content.onSite.mx.price,
      offer: content.onSite.mx.offer,
      checkoutUrl: `${process.env.REACT_APP_BOOKING_MODULE_URL.replace('[domain]', 'mx')}${publicKey}`
    },
    homeKit: {
      price: content.homeKit.mx.price,
      offer: content.homeKit.mx.offer,
      checkoutUrl: `${process.env.REACT_APP_CHECKOUT.replace('[domain]', 'mx')}homekit/${publicKey}`
    }
  }
  const { onSite, homeKit } = countryText

  if (colombia) {
    onSite.subtitle = {
      subtitleOne: content.onSite.co.subtitleOne,
      bolder: content.onSite.co.bolder,
      subtitleTwo: content.onSite.co.subtitleTwo,
    }
    onSite.price = content.onSite.co.price
    onSite.offer = content.onSite.co.offer
    onSite.checkoutUrl = `${process.env.REACT_APP_BOOKING_MODULE_URL.replace('[domain]', 'co')}${publicKey}`

    homeKit.price = content.homeKit.co.price
    homeKit.offer = content.homeKit.co.offer
    homeKit.checkoutUrl = `${process.env.REACT_APP_CHECKOUT.replace('[domain]', 'co')}homekit/${publicKey}`
  }
  return (
    <div className={styles.Container}>
      <p className={styles.Title}>{content.title}</p>
      <div className={styles.CardsWrapper}>
        <MethodCard
          colombia={colombia}
          image={onSiteImg}
          title={content.onSite.title}
          subtitle={onSite.subtitle}
          price={onSite.price}
          offer={onSite.offer}
          textCTA={content.onSite.textCTA}
          devolutionCopy={content.onSite.devolution}
          color={content.onSite.color}
          action={onSite.checkoutUrl}
          fillButton
          id="payAppointmentPreDiagnostic"
        />
        <MethodCard
          colombia={colombia}
          image={printKit}
          title={content.homeKit.title}
          subtitle={colombia ? content.homeKit.subtitleCO : content.homeKit.subtitle}
          price={homeKit.price}
          offer={homeKit.offer}
          textCTA={content.homeKit.textCTA}
          devolutionCopy={content.homeKit.devolution}
          color={content.homeKit.color}
          action={homeKit.checkoutUrl}
          id="payKitPreDiagnostic"
        />
      </div>
    </div>
  )
}

export default ScanMethods
